<template>
		<Button label="下架"  class="p-button-raised p-button-yellow"
							 @click="ModalOpen()"></Button>
   
    <Dialog header="确认下架" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
      
       <p class="text-center">
           是否确认下架该人物？
       </p>
      <hr>
      <div class="text-center">
        <Button label="立即下架" @click="comfirm" icon="pi pi-check" />
         
      </div>
    </Dialog>

   
</template>

<script>
import { useStore } from "vuex";
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
import {takeDownRole} from "../../wallet/LogicMarket";
export default defineComponent({
    name:"takeDownModal",
    emits: ["getListEvent"],
    props: [
        'info'
    ],
    setup(props,{emit}) {
        const store = useStore();
        const info=ref(props.info)
        let display=ref(props.showNameModal);
        let address=ref("");
      
      
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
        const ModalOpen=()=>{
            
             display.value=true
        }
        const comfirm=()=>{
            display.value=false;
            takeDownRole(info.value.role.itemId).then(res=>{
				emit('getListEvent') 
			})
          
            return 
        }

      
     

        return {
            ModalOpen,
            address,
            comfirm,
            display,
            
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
