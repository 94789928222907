<template>
	<div class="grid" v-if="isshow=fasle">
		<div class="col-12">
			<div class="card border-noround mb-0 ">
				<div class="flex align-items-center justify-content-between ">
					<div>
						<p>
							角色筛选：
						</p>
						<Dropdown v-model="dropdownValue" :options="dropdownValues" optionLabel="name" placeholder="Select" />
					</div>
					<Button label="显示我的列表" 
					class="mr-2 p-button-raised p-button-secondary"></Button>
				</div>
			</div>
		</div>
    </div>
	 <div class="content_title mt-4 mb-4">
        市场所有人物 
    </div>
	<div class="grid">
		<div class="col-12 flex justify-content-between">
			 <Button label="刷新人物列表" icon="iconfont icon-jiaose-" 
    			class="mr-2" @click="getListEvent()"></Button>
				
			<Button :label="showListText" @click="getMyListEvent()"></Button>
			
		</div>
	</div>
	<div class="grid mt-2 justify-content-between" v-if="isMyList==0">	
		<div class="col-12  lg:col-6 xl:col-6">
			<p>等级筛选：</p>
			<Button label="All" class="mr-2" @click="filterRole(0,0)" :class="filterNum == 0 ?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="Rank D" class="mr-2" @click="filterRole('D',0)" :class="filterNum=='D'?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="Rank C" class="mr-2" @click="filterRole('C',0)" :class="filterNum=='C'?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="Rank B" class="mr-2" @click="filterRole('B',0)" :class="filterNum=='B'?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="Rank A" class="mr-2" @click="filterRole('A',0)" :class="filterNum=='A'?'active':'p-button-raised p-button-secondary'"></Button>
			<Button label="Rank S" class="mr-2" @click="filterRole('S',0)" :class="filterNum=='S'?'active':'p-button-raised p-button-secondary'"></Button>
		</div>
		<div class="col-12 lg:col-6 xl:col-6" >
			<Pagination :dataAll="pageTotal" :datanum="5"
			@changePage="changePage" 
			:dataCur="pageNow"
				v-if="marketListNow&&pageTotal>0"></Pagination>
		</div>
	</div>
	<!-- <div class="grid mt-4">
		<div class="col-12 lg:col-6 xl:col-6">
			<p>
				等级筛选：
			</p>
		</div>
		
	</div> -->

	<div class="grid mt-4"> 
		<template v-if="isMyList==0">
			<div class="col-12 lg:col-6 xl:col-4" v-for="item,index in marketListNow">
				<div class="card mb-0 MarketCard active">
					<div class="imgCard imgBox">
						<img src="@/assets/img/monkey.jpg" v-if="(item.role.number).toNumber()==1"/>
						<img src="@/assets/img/pig.jpg" v-if="(item.role.number).toNumber()==2"/>
						<img src="@/assets/img/shazeng.jpg" v-if="(item.role.number).toNumber()==3"/>
						<i class="iconfont icon-yingxiaozuozhanshi checkDetail" 
						@click="getRoleInfo(index,item)"/>
						
						<div class="msgBox flex justify-content-between  ">
							<div>
								<h4 class="mb-4">
									{{item.role.username}}
								</h4>
								<h6 class="mb-3">
									<span class="mr-2">等级：{{(item.role.level).toNumber()}}</span>
									<span>ID：{{(item.role.itemId).toNumber()}}</span>
								</h6>
								<div class="innerBox">
									<div class="bolderBox">
										<h5>战力:{{item.minePower.toNumber()}}</h5>
									</div>
									<div class="bolderBox">
										<h5>等级:{{item.role.rank}}</h5>
									</div>
									<div class="bolderBox">
										<h5>体力:{{(item.role.ps).toNumber()}}</h5>
									</div>
									<!-- <div class="bolderBox">
										<h5>天数:{{(item.role.leaseExpireTime).toNumber()}}</h5>
									</div> -->
								</div>
							</div>

							<div class="flex bottom_content">
								<h4 class="mr-4">
									<i class="iconfont icon-jian-tianchong" /> {{item.currentArms}}/10
								</h4>
								<h4>
									<i class="iconfont icon-zhubaopeishi" />
									{{item.currentGems}}/{{item.maxGems.toNumber()}}
								</h4>
							</div>
						
						</div>
						<RoleDetailBox :roleInfo="roleInfo"
						:itemIndex="index" v-if="item.isShow"
						@closeRoleDetail="closeRoleDetail" 
						type="market" />
						<div class="canMineBox flex justify-content-between align-items-center">
							<div class="  innerBox">
							<i class="pi pi-fw pi-check text-success" v-if="item.canMine"></i>
							<i class="pi pi-fw pi-times text-danger" v-else></i>
							<span  class="ml-1">
								canmine
							</span>
						</div>
						<div class="  innerBox">
							<i class="pi pi-fw pi-check text-success" v-if="item.role.leaseExpireTime>0"></i>
							<i class="pi pi-fw pi-times text-danger" v-else></i>
							<span>
								合约({{item.role.leaseExpireTime}})
							</span>
						</div>
						<div class="innerBox">
							<i class="pi pi-fw pi-check text-success" v-if="item.role.ps>0"></i>
							<i class="pi pi-fw pi-times text-danger" v-else></i>
							<!-- <span class="mr-1">{{item.role.ps}}</span> -->
							<span>
								体力
							</span>
						</div>
						</div>
					</div>
					
					<div class="flex align-items-center justify-content-between mt-4">
						<h5 class="title mb-0">
							{{MathPrice(Number(ethers.utils.formatEther(item.price,2)))}}
							<span class="text-warning"> {{tokenName}}</span>
							<p style="font-size:small">
								卖家：{{item.sellerAddress.slice(0,5)}}...{{item.sellerAddress.slice(-4)}}
							</p>
						</h5>
						
						
						<Button  icon="iconfont icon-gouwucheman" 
						class="p-button-raised p-button-yellow"
						@click="buyRole(item)"></Button>
			
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<template v-for="item,index in marketListNow">
				<div class="col-12 lg:col-6 xl:col-4" 
				
				v-if="item.sellerAddress==$store.getters.getErc20Address">
						<div class="card mb-0 text-center MarketCard active">
						<div class="imgCard imgBox">
						<img src="@/assets/img/monkey.jpg" v-if="(item.role.number).toNumber()==1"/>
						<img src="@/assets/img/pig.jpg" v-if="(item.role.number).toNumber()==2"/>
						<img src="@/assets/img/shazeng.jpg" v-if="(item.role.number).toNumber()==3"/>
						<i class="iconfont icon-yingxiaozuozhanshi checkDetail" 
						@click="getRoleInfo(index,item)"/>
						
						<div class="msgBox flex justify-content-between  ">
							<div class="text-left">
								<h4 class="mb-4">
									{{item.role.username}}
								</h4>
								<h6 class="mb-3">
									<span class="mr-2">等级：{{(item.role.level).toNumber()}}</span>
									<span>ID：{{(item.role.itemId).toNumber()}}</span>
								</h6>
								<div class="innerBox">
									<div class="bolderBox">
										<h5>战力:{{item.minePower.toNumber()}}</h5>
									</div>
									<div class="bolderBox">
										<h5>等级:{{item.role.rank}}</h5>
									</div>
									<div class="bolderBox">
										<h5>体力:{{(item.role.ps).toNumber()}}</h5>
									</div>
									<!-- <div class="bolderBox">
										<h5>天数:{{(item.role.leaseExpireTime).toNumber()}}</h5>
									</div> -->
								</div>
							</div>

							<div class="flex bottom_content">
								<h4 class="mr-4">
									<i class="iconfont icon-jian-tianchong" /> {{item.currentArms}}/10
								</h4>
								<h4>
									<i class="iconfont icon-zhubaopeishi" />
									{{item.currentGems}}/{{item.maxGems.toNumber()}}
								</h4>
							</div>
						
						</div>
						<RoleDetailBox :roleInfo="roleInfo"
						:itemIndex="index" v-if="item.isShow"
						@closeRoleDetail="closeRoleDetail" 
						type="market" />
						<div class="canMineBox flex justify-content-between align-items-center">
							<div class="  innerBox">
							<i class="pi pi-fw pi-check text-success" v-if="item.canMine"></i>
							<i class="pi pi-fw pi-times text-danger" v-else></i>
							<span  class="ml-1">
								canmine
							</span>
						</div>
						<div class="  innerBox">
							<i class="pi pi-fw pi-check text-success" v-if="item.role.leaseExpireTime>0"></i>
							<i class="pi pi-fw pi-times text-danger" v-else></i>
							<span>
								合约({{item.role.leaseExpireTime}})
							</span>
						</div>
						<div class="innerBox">
							<i class="pi pi-fw pi-check text-success" v-if="item.role.ps>0"></i>
							<i class="pi pi-fw pi-times text-danger" v-else></i>
							<!-- <span class="mr-1">{{item.role.ps}}</span> -->
							<span>
								体力
							</span>
						</div>
						</div>
					</div>
					
					<div class="flex align-items-center justify-content-between mt-4">
						<h5 class="title mb-0 text-left">
							{{MathPrice(Number(ethers.utils.formatEther(item.price,2)))}}
							<span class="text-warning"> {{tokenName}}</span>
								<p style="font-size:small">
								卖家：{{item.sellerAddress.slice(0,5)}}...{{item.sellerAddress.slice(-4)}}
							</p>
						</h5>
						
								<takeDownModal :info="item" @getListEvent=getListEvent />
								
					
							</div>
						</div>
				
				</div>
			</template>
		</template>
		
	</div>
     
</template>

<script>
import { useStore } from "vuex";
import { defineComponent,ref,store,computed} from 'vue';
import RoleDetailBox from "../../components/RolePage/detailBox.vue";
import * as LogicMarket from "../../wallet/LogicMarket";
import {getRole} from "../../wallet/LogicRole";
import Pagination from "../../components/Pagination/Pagination.vue";
import takeDownModal from "../../components/Modal/takeDownModal.vue";
import {market_setApprovalForAll,market_isApprovedForAll} from "../../wallet/nftAuto";
export default defineComponent({
    name:"armsPage",
	
 	components: {
		RoleDetailBox,
		Pagination,
		takeDownModal
	},
	setup(){
		const store = useStore();
		let dropdownValue='';
		let refreshPage=ref(true);
		let dropdownValues = [
			{name: '孙悟空', code: '0'},
		
		]
		let marketList=ref('');
		let marketListNow=ref('');
		let filterNum=ref(0);
		let showListText=ref("我的上架");
		let isMyList=ref(0);
		//分页
		let pageTotal=ref(2);
		let limit=ref(10);
		let offset=ref(limit.value);
        const pageNow=ref(1);
		const getListEvent=()=>{
			marketList.value='';
			LogicMarket.getRoleMarketList().then(res=>{
				console.log("获取市场所有装备22",res)
				
				 let newRes = res.map((item, index, res) => {
                    let newArr = [];
                    newArr.canMine = item.canMine;
                    newArr.currentArms = item.currentArms;
                    newArr.currentGems = item.currentGems;
                    newArr.maxGems = item.maxGems;
                    newArr.minePower = item.minePower;
                    newArr.price = item.price;
                    newArr.role = item.role;
					newArr.sellDate = item.sellDate;
					newArr.sellerAddress = item.sellerAddress;
                    newArr.isShow = false;
                    //res.concat({"isShow":false});
                    return newArr;
                });
				marketList.value=newRes;
				marketListNow.value=newRes;
				console.log("当前总页数1",marketListNow.value);
				pageTotal.value=Math.ceil(marketList.value.length/limit.value);
				if(isMyList.value==0){
					
					changePage(1);
					
				}
				
			}).catch(error=>{
				console.log("调用方法失败")
			})
		}
		getListEvent();

		//购买角色
		const buyRole=(info)=>{
			
			LogicMarket.buyRole(info.role.itemId.toNumber(),info.sellerAddress).then(res=>{
				
				// marketList.value=res;
				getListEvent();

			}).catch(error=>{
				console.log("调用方法失败")
			})
		}
		
	
		//获取当前角色信息
		let isShowInfo = ref(false);
		let roleInfo = ref();
		let roleInfoType = ref(0);
		const getRoleInfo = (index,item) => {
			
			marketList.value[index].isShow = true;
				roleInfo.value='';
				getRole(item.role.itemId.toNumber()).then(res => {
					console.log("市场获取当前角色信息",res);
					roleInfo.value=res;
				})		
			
			// })
		}
		//关闭角色详情
		const closeRoleDetail = (itemIndex) => {
			marketList.value[itemIndex].isShow = false;
		}

		 //检查授权情况   
        const isMarketTokenAuth=()=>{
             LogicMarket.isAllowance().then(res=>{
                 console.log("武器组件调用检查授权",res);
                 if(res>0){
                      store.commit("UpdateIsMarketTokenAuth",true)
                     return true
                 }else{
                     store.commit("UpdateIsMarketTokenAuth",false)
                     return false;
                 }
             })
        }
        //isMarketTokenAuth()
        //授权武器合约
        const authTips=ref("当前暂未授权市场Token合约,点击授权");
        const isAuthNow=ref(false);
        const approveMarket=()=>{
            isAuthNow.value=true;
            authTips.value="正在唤起钱包授权";
            LogicMarket.toApprove().then(res=>{
                console.log("武器组件调用授权",res);
                isAuthNow.value=false;
                if(res){
                      store.commit("UpdateIsMarketTokenAuth",true)
                   // store.commit("UpdateIsMarketNftAuth",true)
                    return true
                }else{
                     store.commit("UpdateIsMarketTokenAuth",false)
                   // store.commit("UpdateIsMarketNftAuth",false)
                    return false;
                }
            })
        }

        //检查NFT授权
        market_isApprovedForAll().then(res=>{
           
            if(res){
               store.commit("UpdateIsMarketNftAuth",true)
            }else{
                store.commit("UpdateIsMarketNftAuth",false)
            }
        })

        //market NFT 授权
        const authTipsNft=ref("当前暂未授权市场Nft合约,点击授权");
        const isAuthNftNow=ref(false);
        const toNftMarket=()=>{
            isAuthNftNow.value=true;
            authTipsNft.value="正在唤起钱包授权";
            market_setApprovalForAll().then(res=>{
                console.log("武器组件调用授权",res);
                isAuthNftNow.value=false;
                if(res){
                   
                    store.commit("UpdateIsMarketNftAuth",true)
                    return true
                }else{
                  
                    store.commit("UpdateIsMarketNftAuth",false)
                    return false;
                }
            })
        }

		//筛选人物
		const filterRole=(quality,type)=>{
			//refreshPage.value=false;
			marketListNow.value='';
			filterNum.value=quality;
			if(quality!=0){
				let filterArr=marketList.value.filter((value,key,arr) => {
					return value.role.rank == quality? true :  false; 
				})
				marketListNow.value=filterArr;
			}else{
				marketListNow.value=marketList.value;
			}
			console.log("最后1")
			if(type==0){
				pageNow.value=1;
				offset.value=limit.value;
				
				filterPage();
				
				console.log("最后2")
				//refreshPage.value=true;
			}
		
		}
		//分页组件事件
		const changePage=(val)=>{
			
			// marketListNow.value.slice(val*limit.value,)
			pageNow.value=val;
            offset.value=(val-1)*limit.value;
			filterRole(filterNum.value,1);
			filterPage();
			
			
		}

		//分页筛选
		const filterPage=(arr)=>{
			marketListNow.value=marketListNow.value.slice((pageNow.value-1)*limit.value,(pageNow.value)*limit.value)
			
		}

		//筛选出自己
		const getMyListEvent=(type)=>{
			getListEvent();
			
			
			if(isMyList.value==0){
				isMyList.value=1;
				showListText.value="显示全部";
			}else{
				isMyList.value=0;
				showListText.value="我的上架";
				
			}
			
			
		}

		

		return{
			filterNum,
			marketListNow,
			dropdownValue,
			dropdownValues,
			marketList,
			getListEvent,
			buyRole,
			getRoleInfo,
			closeRoleDetail,
			roleInfo,
			approveMarket,
            authTips,
            toNftMarket,
            isAuthNftNow,
            authTipsNft,
			filterRole,
			pageTotal,
			limit,
			pageNow,
			changePage,
			showListText,
			getMyListEvent,
			isMyList,
			refreshPage,
			
		}
	}
	
})

</script>
<style lang="scss" scoped>
.imgBox {
			position: relative;
			h4,h5,h6{
				margin: 0;	
				font-weight: bold;
				color:#CBDDE6;
			}
			h5{
				font-size: 1rem;
			}
			h6{
				font-size: 1rem;
				
			}
			.msgBox {
				width: 100%;
				bottom: 0;
				position: absolute;
				height:100%;
				background: rgb(0,0,0,0.6);
				flex-direction:column;
				padding: 1rem;
				.innerBox{
					
					
					
					text-align: left;
					
					.bolderBox{
						background:#16202F;
						padding: 10px 1.1rem;
						border-left: 5px solid $themeColor;
						margin-bottom: 10px;
						width: 45%;
						&:nth-child(2) {
							border-left: 5px solid $themeColor;
						}
						&:last-child {
							margin-right: 0;
						}
					}
					&:last-child {
						border-right: 0px;
					}
				}
				.iconfont {
					color: $themeColor;
					font-size: 1.5rem;
				}
				.bottom_content{
					margin-bottom: 80px;
				}
			}

			.armBox {
				position: absolute;
				top: 0;
				width: 100%;
				background: #17212f;
				padding: 10px;
				text-align: right;
				justify-content: flex-end
			}
		}
.imgCard:deep{
	position: relative;
	.checkDetail{
		position: absolute;
		font-size: 2rem;
		top:10px;
		right:10px;
		color:$themeColor;
		z-index:2;
	}
	&:hover{
		border: none;
	}
	.canMineBox{
		height: auto;
		position: absolute;
		bottom: 0;
		width: 100%;
		background: #17212f;
		background: #17212f;
		padding: 5px 0;
		.innerBox{
			flex: 1;
			margin: 5px;
			text-align: center;
			background: #1e2c40;
				padding: 10px 2px;
		}
	}
	.ListBoxShow{
		align-items: flex-start;
		z-index: 9;
		height:auto;
		.listBoxH{
			    height: 285px;
		}
	}
}
</style>

