<template>
  <div class="formgroup flex">
      <InputText id="username" type="Number" v-model="inputPageNum" style="width:100%" />
     	<Button label="搜索" class="ml-2"
       @click="sreachPage"></Button>
  </div>
  <!-- 自定义分页组件 -->
  <div class="page-bar" v-if="all>1">
    <ul>
      <li class="pre" @click="preClick()">
        <!-- 点击上一页 -->
        上一页
      </li>
      <template v-for="index in indexs"
       >
        <li class="li_a"
        @click="btnClick(index)"  :class="{ active: cur == index }">
        <!-- 页码 -->
       {{ index }}
      </li>
      </template>
      
      <li  class="prev-next" @click="nextClick()">
        <!-- 点击下一页 -->
        下一页
      </li>
    
     
    </ul>
  </div>
</template>
<script>
import {
    defineComponent,
    ref,
    computed,
    watch,
    defineEmit,
    reactive,
    toRefs
} from "vue";
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
export default defineComponent({
  name: "Pagination",
  props: {
    // 总页数
    'dataAll': {
      type: Number,
      default: 100,
      required: true
    },
    // 当前页数
    'dataCur': {
      type: Number,
      default: 1,
      required: true
    },
    // 页面条数
    'datanum': {
      type: Number,
      default: 7
    },
    // 数据总量
    'dataDatanum': {
      type: Number,
      default: 456
    },
  },
  setup(props, content) 
  {
    
    const data = reactive({
      all: props.dataAll, //总页数
      cur: Number(props.dataCur),//当前页码
      num: props.datanum, //一页显示的数量  奇数
      dataNum: props.dataDatanum,//数据的数量
      jumpPage:0,// 跳转页码
      indexs:computed(()=>{
        var left = 1;
        var right = data.all;
        var ar = [];
        if (data.all >= data.num) 
        {
          if (data.cur > 3 && data.cur < data.all - 2) 
          {
            left = data.cur - (data.num - 1) / 2;
            right = Number(data.cur) + Number((data.num - 1) / 2);
          } 
          else 
          {
            if (data.cur <= 3) 
            {
              left = 1
              right = data.num
            } 
            else 
            {
              right = data.all
              left = data.all - (data.num - 1);
            }
          }
        }
        while (left <= right) 
        {
          ar.push(left)
          left++
        }
        return ar
      })
    });
 
    
    const btnClick = (val) => { 
      if (val != data.cur) 
      {
         
          //let oldVal= data.cur;
          data.cur = val;
       
        content.emit('changePage', data.cur);
      }
    };
 
    const pageClick = () => {
      //父组件通过changePage方法来接受当前的页码
     
      content.emit('changePage', data.cur)
    }
 
    
    const changePage = () => {
      if (data.jumpPage > data.all || data.jumpPage < 1 || isNaN(data.jumpPage) )
      {
       
      }
     
      content.emit('changePage', Number(data.jumpPage),data.cur)
    }
    let inputPageNum=ref();
    const sreachPage=()=>{
       content.emit('changePage',inputPageNum.value)
    }

    // 翻页
    const nextClick=()=>{
      
      // let jumPageNow=data.cur+data.num;
      let jumPageNow=data.cur+1;
      if(jumPageNow > data.all ){
         data.cur=data.all;
         jumPageNow=data.all;
      }else if(jumPageNow > data.all || jumPageNow < 1 || isNaN(jumPageNow) ){
       
        return;
      }
      data.cur=jumPageNow;
      // // content.emit('changePage', Number(jumPageNow),data.cur)
      content.emit('changePage', Number(jumPageNow))
    }
    
    const preClick=()=>{
      
      // let jumPageNow=data.cur-data.num;
     let jumPageNow=data.cur-1;
      if(jumPageNow < 1 ){
         jumPageNow=1;
         data.cur=1;
        
      }else if(jumPageNow > data.all || jumPageNow < 1 || isNaN(jumPageNow) ){
        
        return;
      }else{
         data.cur=jumPageNow;
      }
      
      //  content.emit('changePage', Number(jumPageNow),data.cur)
         content.emit('changePage', Number(jumPageNow))
    }
 
  
    const dataRef = toRefs(data);
   
    watch(
      () => props.dataCur,
      () => {
        console.log("页数组件接受参数",props.dataCur);
        data.cur=props.dataCur;
        inputPageNum.value='';
      }
    );
    return {
      btnClick,
      pageClick,
      changePage,
      ...dataRef,
      nextClick,
      preClick,
      sreachPage,
      inputPageNum
    }
  },
   components : {
       Button,
        InputText
    }
    
});
</script>
<style lang="scss" scoped>
.formgroup{
  button{
     white-space: nowrap;
  }
}
.page-bar{
    ul{
        display: flex;
        align-items: center;
        padding: 0;
        font-weight: bold;
        justify-content: flex-end;
         .banclick,li{
             color:#A99ECE;
            padding: 10px
         }
         li{
           color: #fff;
            // background: #16202F;
            // border: 1px solid #16202F;
            
            padding: 0.5rem 1rem;
            font-size: 1rem;
            transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
            border-radius: 3px;
           
           list-style: none;
             cursor: pointer;
            &.active{
                color:$text-themeBg-color;
                background: $themeColor;
            }
            .banclick{
                cursor: auto;
                 padding: 0;
            }
            &.pre{
              padding-left: 0;
            }
         }
         
    }
   
}

</style>